import React from 'react';
import cx from 'classnames';

import styles from '~/components/the-shed/components/article-block.module.scss';

const ArticleBlock = ({ children, className }) => (
  <article className={cx(className, styles.articleBlock)}>{children}</article>
);

export { ArticleBlock };
